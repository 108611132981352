import React from 'react'
import {graphql} from 'gatsby'
import Container from '../styles/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import HeroImage from '../components/contact/contact_heroimage'
import ContactInformation from '../components/contact/contact_information'

const Contact = props => {
  return (
    <Layout>
      <SEO
        title="Kontakt" />
      <HeroImage />
      <Container>
        <ContactInformation />
      </Container>
    </Layout>
  )
}

export default Contact
