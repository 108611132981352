import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const HerImage = styled(Img)`
  width: 100%;
  max-height: 60vh;
  margin-bottom: ${props => props.theme.space[6]};
  
  @media only screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    margin-bottom: ${props => props.theme.space[12]};
    margin-top: ${props => props.theme.space[8]};
  }
`

export default function ContactHeroImage ({path}) {
  return (
    <StaticQuery
      query={graphql`
        query ContactHeroImage {
          file(relativePath: {eq: "at_the_table.png"}) {
            id
            childImageSharp {
              id
              fluid(maxWidth: 1072, maxHeight: 550) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }`}
      render={data => (
        <HerImage fluid={data.file.childImageSharp ? data.file.childImageSharp.fluid : null} alt='ueber mich' />
      )}
    />
  )
}
