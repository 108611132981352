import React from 'react'
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import {AiOutlineMail, AiOutlinePhone, AiOutlineInstagram} from 'react-icons/ai'

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.space[12]};

  @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: row;
  }

  .text {
    width: 100%;
    margin-right: ${props => props.theme.space[10]};

    @media only screen and (min-width: ${props => props.theme.breakpoints[1]}) {
      width: 50%;
    }
    
    p {
      margin-top: 0;
    }
  }

  .info {
    address {
      font-style: normal;
    }
  }
`

export default function ContactHeroImage ({path}) {
  return (
    <StaticQuery
      query={graphql`
        query ContactInformation {
          contact: sanityContact {
            _rawContacttext
            place
            street
            telephonenumber
            mail
            instagram
          }
        }`}
      render={data => (
        <Flexbox>
          <div className='text'>
            <BlockContent blocks={data.contact._rawContacttext} />
          </div>
          <div className='info'>
            <address>
              Carolin Vollmer <br />
              {data.contact.place} <br />
              {data.contact.street}
            </address>
            <p><AiOutlinePhone /> {data.contact.telephonenumber}</p>
            <p><AiOutlineMail /> {data.contact.mail}</p>
            <a href={data.contact.instagram} alt='petite hirondelle instagram'><AiOutlineInstagram /> Instagram</a>
          </div>
        </Flexbox>
      )}
    />
  )
}
